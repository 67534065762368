<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">保护管理规划编制记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="编制和公布状态" prop="BZHGBZT">
                    <el-select
                        v-model="formdata.dataDic.BZHGBZT"
                        filterable
                        :clearable="true"
                        :disabled="true"
                    >
                        <el-option label="在编" value="0"></el-option>
                        <el-option label="通过审批待公布" value="3"></el-option>
                        <el-option label="已公布且现行" value="1"></el-option>
                        <el-option label="已过期" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="保护管理规划名称">
                    <el-input
                        v-model="formdata.dataDic.MC"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="组织编制单位">
                    <el-input
                        v-model="formdata.dataDic.ZZBZDW"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="编制单位">
                    <el-input
                        v-model="formdata.dataDic.BZDW"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    v-if="
                        ['1', '2', '3'].indexOf(formdata.dataDic.BZHGBZT) > -1
                    "
                    label="规划期限"
                >
                    <el-col :span="11">
                        <el-date-picker
                            v-model="formdata.dataDic.GHKSNF"
                            align="right"
                            value-format="yyyy"
                            type="year"
                            placeholder="选择年"
                            :disabled="true"
                        ></el-date-picker>
                    </el-col>
                    <el-col :span="2" style="text-align: center">-</el-col>
                    <el-col :span="11">
                        <el-date-picker
                            v-model="formdata.dataDic.GHJZNF"
                            align="right"
                            value-format="yyyy"
                            type="year"
                            placeholder="选择年"
                            :disabled="true"
                        ></el-date-picker>
                    </el-col>
                </el-form-item>
                <el-form-item
                    v-if="
                        ['0', '1', '3'].indexOf(formdata.dataDic.BZHGBZT) > -1
                    "
                    label="是否为修编"
                    prop="SFWXB"
                >
                    <el-select
                        v-model="formdata.dataDic.SFWXB"
                        filterable
                        :disabled="true"
                    >
                        <el-option value="是"></el-option>
                        <el-option value="否"></el-option>
                    </el-select>
                </el-form-item>
                <br />
                <el-form-item
                    v-if="
                        ['1', '2', '3'].indexOf(formdata.dataDic.BZHGBZT) > -1
                    "
                    label="国家文物局批准文号"
                    prop="GJWWJPZWH"
                    :class="{ 'short-input': gjwwjFileList.length === 0 }"
                >
                    <el-input
                        v-model="formdata.dataDic.GJWWJPZWH"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="true"
                    >
                    </el-input>
                    <ul class="file-list">
                        <li
                            v-for="(item, i) in gjwwjFileList"
                            :key="i"
                            :class="item.percentage ? 'high-li' : ''"
                        >
                            <a
                                target="_blank"
                                :href="item.FilePath"
                                :title="item.FileName"
                            >
                                <span>{{ item.FileName }}</span>
                            </a>
                            <el-progress
                                v-if="item.percentage"
                                :percentage="item.percentage"
                            ></el-progress>
                        </li>
                    </ul>
                </el-form-item>
                <el-form-item
                    v-if="['1', '2'].indexOf(formdata.dataDic.BZHGBZT) > -1"
                    label="省级政府公布文号"
                    prop="SJZFGBWH"
                    :class="{ 'short-input': sjzfFileList.length === 0 }"
                >
                    <el-input
                        v-model="formdata.dataDic.SJZFGBWH"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="true"
                    >
                    </el-input>
                    <ul class="file-list">
                        <li
                            v-for="(item, i) in sjzfFileList"
                            :key="i"
                            :class="item.percentage ? 'high-li' : ''"
                        >
                            <a
                                target="_blank"
                                :href="item.FilePath"
                                :title="item.FileName"
                            >
                                <span>{{ item.FileName }}</span>
                            </a>
                            <el-progress
                                v-if="item.percentage"
                                :percentage="item.percentage"
                            ></el-progress>
                        </li>
                    </ul>
                </el-form-item>
                <el-form-item
                    v-if="['1', '2'].indexOf(formdata.dataDic.BZHGBZT) > -1"
                    label="规划文本文件"
                    prop="ghwbwj"
                >
                    <ul class="file-list file-ghwbwj-list">
                        <li
                            v-for="(item, i) in ghwbwjFileList"
                            :key="i"
                            :class="item.percentage ? 'high-li' : ''"
                        >
                            <a
                                target="_blank"
                                :href="item.FilePath"
                                :title="item.FileName"
                            >
                                <span>{{ item.FileName }}</span>
                            </a>
                            <el-progress
                                v-if="item.percentage"
                                :percentage="item.percentage"
                            ></el-progress>
                        </li>
                    </ul>
                </el-form-item>
                <basic-upload
                    ref="uploadFile"
                    :isEdit="isEdit"
                    :itemId="itemId"
                    :metaData.sync="metaData"
                    basicType="document"
                ></basic-upload>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "bhglghbzjl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "1601",
                dataDic: {
                    BZHGBZT: "",
                    // BZHGBZT: '',
                    // ZZBZDW: '',
                    // BZDW: '',
                },
            },
            rules: {
                bhgljgmc: [
                    {
                        required: true,
                        message: "请填写保护管理机构名称",
                        trigger: "blur",
                    },
                ],
            },
            enumData: [],
            startYear: "",
            endYear: "",
            gjwwjFileList: "",
            sjzfFileList: "",
            ghwbwjFileList: "",
            metaData: [],
        };
    },
    created() {
        if (this.formdata.dataDic.GHQX) {
            this.startYear = this.formdata.dataDic.GHQX.split("-")[0];
            this.endYear = this.formdata.dataDic.GHQX.split("-")[1];
        }
    },
    mounted() {
        this.GetEnum();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "1601",
            });
            this.enumData = res[0].dataItems;
        },
        changeYear() {
            this.formdata.dataDic.GHQX = `${this.startYear}-${this.endYear}`;
        },
        GetDataNext() {
            this.getMetaJsonData();
        },
        // 处理获取到的附件列表
        getMetaJsonData() {
            this.gjwwjFileList = [];
            this.sjzfFileList = [];
            this.ghwbwjFileList = [];
            this.metaData = [];
            let indexArr = [];
            let metaJson = this.formdata.metaJson;
            metaJson.forEach((item, index) => {
                item.FileName = item.fjmc;
                item.FilePath = item.path;
                if (item.lx === "1901") {
                    // 国家文物局批准文号附件
                    item.code = item.lx;
                    this.gjwwjFileList.push(item);
                } else if (item.lx === "1902") {
                    // 省级政府公布文号附件
                    item.code = item.lx;
                    this.sjzfFileList.push(item);
                } else if (item.lx === "1903") {
                    // 规划文本文件附件
                    item.code = item.lx;
                    this.ghwbwjFileList.push(item);
                } else if (item.lx === "3") {
                    // 规划文件
                    item.code = "JCSJ";
                    this.metaData.push(item);
                    indexArr.push(index);
                }
            });
            indexArr.reverse().forEach((item) => {
                this.formdata.metaJson.splice(item, 1);
            });
        },
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
